<template>
  <PublicLayout>
    <div class="container">
      <img
        src="@/assets/img_intro.png"
        class="img-intro"
        alt=""
      >

      <h1 v-t="'Credits.title'" />

      <div class="section">
        <!-- Sorry for the internationalization :) -->
        <p
          align="justify"
          style="margin-bottom: 0in"
        >
          <b>Images, texte et contenus multimédia</b>
          <br>
        </p>
        <p
          align="justify"
          style="margin-bottom: 0in"
        >
          Toutes
          les images, les textes et les contenus multimédia présents sur&nbsp;le
          site <b>cadyco.education</b>&nbsp;sont soumis à la licence
          <img
            src="@assets/licence_img.png"
          >
        </p>
        <p
          align="justify"
          style="margin-bottom: 0in"
        >
          n’autorisant
          quiconque qu’à télécharger les œuvres et à les partager tant
          qu'il les crédite en citant le nom &quot;CaDyCo&quot;, mais sans les
          modifier de quelque façon que ce soit ni les utiliser à des fins
          commerciales.
        </p>
        <p
          align="justify"
          style="line-height: 100%; margin-bottom: 0in"
        >
          <font face="Liberation Serif, serif">
            <font
              size="3"
              style="font-size: 12pt"
            >
              <span style="font-style: normal"><span style="font-weight: normal">Pour
                toutes questions sur ce </span></span>
            </font>
          </font>qui est autorisé
          par cette licence, vous pouvez vous reporter au site suivant&nbsp;:
          <a
            target="_blank"
            href="https://creativecommons.org/licenses/"
          > https://creativecommons.org/licenses/</a>
        </p>
        <p
          align="justify"
          style="line-height: 100%; margin-bottom: 0in"
        >
          <br>
        </p>
        <p
          align="justify"
          style="margin-bottom: 0in"
        >
&nbsp;
        </p>
        <p
          align="justify"
          style="margin-bottom: 0in"
        >
          <b>Objets&nbsp;CaDyCo&nbsp;</b>
        </p>
        <p
          align="justify"
          style="margin-bottom: 0in"
        >
&nbsp;
        </p>
        <p
          align="justify"
          style="margin-bottom: 0in"
        >
          Les
          données des objets&nbsp;CaDyCo&nbsp;proviennent de sources
          différentes, incluant des données institutionnelles propre à
          chaque institution de formation&nbsp;publique.&nbsp;
        </p>
        <p
          align="justify"
          style="margin-bottom: 0in"
        >
&nbsp;
        </p>
        <p
          align="justify"
          style="margin-bottom: 0in"
        >
          De
          ce fait, toute utilisation des données en lien avec des
          objets&nbsp;CaDyCo&nbsp;est libre de droit, mais implique&nbsp;à
          minima l’indication de la provenance des données sous la forme
          d’une référence.&nbsp;En cas de doute,&nbsp;merci infiniment de
          bien vouloir nous contacter à&nbsp;<a
            href="mailto:contact@cadyco.education"
            target="_top"
          >contact@cadyco.education</a>.&nbsp;
        </p>
        <p
          align="justify"
          style="margin-bottom: 0in"
        >
&nbsp;
        </p>
        <p
          align="justify"
          style="margin-bottom: 0in"
        >
          <b>Indicateurs
            et données associées&nbsp;</b>
        </p>
        <p
          align="justify"
          style="margin-bottom: 0in"
        >
          Les
          données des indicateurs sont des données&nbsp;soient en&nbsp;open
          source, soient&nbsp;liées à des contrats exclusifs entre le
          fournisseur des données et&nbsp;les&nbsp;administrateurs·rices&nbsp;CaDyCo&nbsp;en
          Suisse et en France.&nbsp;Chaque indicateur précise les données
          utilisées&nbsp;et&nbsp;les droits d’auteur·e&nbsp;associés à
          ces données.&nbsp;
        </p>
        <p
          align="justify"
          style="margin-bottom: 0in"
        >
&nbsp;
        </p>
        <p
          align="justify"
          style="margin-bottom: 0in"
        >
          De
          ce fait, les données comme les indicateurs&nbsp;de ce site ne
          peuvent être reproduits&nbsp;que ce soit en usage privé ou
          professionnel, et /ou publiés&nbsp;sur un autre site Internet, et/ou
          sur tout autre support numérique ou physique, sans l’accord
          préalable&nbsp;des&nbsp;administrateurs·rices&nbsp;CaDyCo.&nbsp;
        </p>
        <p
          align="justify"
          style="margin-bottom: 0in"
        >
&nbsp;
        </p>
        <p
          align="justify"
          style="margin-bottom: 0in"
        >
          Si
          vous désirez utiliser un jeu de données ou un indicateur pour
          vous-même, pour impression, ou toute autre possibilité, merci de
          bien vouloir nous contacter à&nbsp;<a
            href="mailto:contact@cadyco.education"
            target="_top"
          >contact@cadyco.education</a>.
        </p>
        <p
          align="justify"
          style="margin-bottom: 0in"
        >
          <br>
        </p>
        <p
          align="justify"
          style="margin-bottom: 0.16in"
        >
          <font color="#333333">
            <font face="Liberation Sans, serif">
              <font
                size="2"
                style="font-size: 11pt"
              >
                <b>Crédits
                  v</b>
              </font>
            </font>
          </font><font color="#333333">
            <font face="Liberation Sans, serif">
              <font
                size="2"
                style="font-size: 11pt"
              >
                <b>isuels
                </b>
              </font>
            </font>
          </font>
        </p>
        <p
          align="justify"
          style="margin-bottom: 0.16in"
        >
          <font color="#333333">
            <font face="Liberation Sans, serif">
              <font
                size="2"
                style="font-size: 11pt"
              >
                Les
                Deux Dandys
              </font>
            </font>
          </font>
        </p>
      </div>
    </div>
  </PublicLayout>
</template>

<script>
import PublicLayout from '@/router/layouts/PublicLayout.vue'

export default {
  name: 'Credits',
  components: {
    PublicLayout
  }
}
</script>

<style lang="scss" scoped>
@import "@design";

.container {
  position: relative;
  padding: 40px 15% 0 15%;
  overflow-y: auto;
  background-color: $public-bg;
  display: flex;
  flex-direction: column;
  align-items: center;

  .img-intro {
    position: absolute;
    top: -25px;
    left: -150px;
    height: 800px;
    z-index: 0;
  }

  h1 {
    font-size: 40px;
    margin-bottom: 36px;
    color: $color-light-text;
    z-index: 1;
  }

  .section {
    margin: 50px 0 100px 0;
    padding: 0 20px;
    width: 100%;
    max-width: 832px;
    position: relative;
    background-color: $color-body-bg;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 6px;
    size: 8.27in 11.69in;
    page-break-before: always;
    p { line-height: 115%; margin-bottom: 0.1in; background: transparent }
    a:link { color: #000080; text-decoration: underline }
  }
}

</style>
